// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import WhyChooseUs from './pages/whyus/WhyChooseUs';
import About from './pages/about/About';
import CoursesSection from './components/ui/courses-offered/CoursesSection';
import Testimonials from './components/ui/testimonials/Testimonials';
import SuccessStories from './components/ui/success-stories/SuccessStories';
import ContactForm from './components/ui/contactform/ContactForm';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/scrolltotop/ScrollToTop';


const App = () => {
  return (
    <Router>
      <ScrollToTop /> 
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/WhyUs" element={<WhyChooseUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<CoursesSection />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/success-stories" element={<SuccessStories />} />
        <Route path="/contact" element={<ContactForm/>} />
      </Routes>
      <Footer/>
    </Router>
    
  );
};

export default App;