import React from 'react';
import { Carousel } from 'react-responsive-carousel'; // Importing the carousel component
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importing carousel styles
import './EventsSection.css'; // Importing the CSS for styling

const EventsSection = () => {
  const events = [
    {
      image: '/events/1.jpeg', // Change these paths to your actual images
      points: [
        'Interaction with College Students on upcoming Recruitment Exams',
        'One to One Doubt Clearing Session',
        'Free Mock Test for ADRE Aspirants',
        'Free Mock Test for Various Recruitment Exams',
      ],
    },
    {
      image: '/events/2.jpeg',
      points: [
        'Webinar on Effective Study Techniques',
        'Guest Lecture by Successful Alumni',
        'Mock Interview Sessions',
        'Networking Opportunities with Experts',
      ],
    },
    {
      image: '/events/3.jpeg',
      points: [
        'Free Workshops on Current Affairs',
        'Group Study Sessions',
        'Tips and Tricks for Civil Services Exams',
        'Interactive Q&A with Faculty Members',
      ],
    },
    {
      image: '/events/4.jpeg',
      points: [
        'Free Workshops on Current Affairs',
        'Group Study Sessions',
        'Tips and Tricks for Civil Services Exams',
        'Interactive Q&A with Faculty Members',
      ],
    },
    // Add more events as needed
  ];

  return (
    <div className="events-container">
      <h2>Events Organized by us</h2>
      <Carousel showThumbs={false} autoPlay infiniteLoop interval={5000}>
        {events.map((event, index) => (
          <div key={index} className="events-content">
            <div className="events-image">
              <img src={event.image} alt={`Event ${index + 1}`} />
            </div>
            <div className="events-details">
              <ul>
                {event.points.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default EventsSection;
