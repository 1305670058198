import React from 'react';
import { Carousel } from 'react-responsive-carousel'; // Importing the carousel component
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importing carousel styles
import './InfrastructureSection.css';

const InfrastructureSection = () => {
  return (
    <div className="infrastructure-container">
      <div className="infrastructure-content">
        <h2>Our Infrastructure</h2>
        <ul>
          <li>Well-equipped classrooms</li>
          <li>Library with extensive resources</li>
          <li>Online resources and digital library access</li>
        </ul>
      </div>
      <div className="infrastructure-carousel">
        <Carousel showThumbs={false} autoPlay infiniteLoop>
            
          <div>
            <img src="/infrastructure/1.jpeg" alt="Classroom" />
            <p className="legend">Well-equipped classrooms</p>
          </div>

          <div>
            <img src="/infrastructure/2.jpeg" alt="Classroom" />
            <p className="legend">Well-equipped classrooms</p>
          </div>

          <div>
            <img src="/infrastructure/3.jpeg" alt="Classroom" />
            <p className="legend">Well-equipped classrooms</p>
          </div>

          <div>
            <img src="/infrastructure/4.jpeg" alt="Classroom" />
            <p className="legend">Well-equipped classrooms</p>
          </div>
          <div>
            <img src="/infrastructure/5.jpeg" alt="Classroom" />
            <p className="legend">Well-equipped classrooms</p>
          </div>
          <div>
            <img src="/infrastructure/6.jpeg" alt="Classroom" />
            <p className="legend">Well-equipped classrooms</p>
          </div>
          <div>
            <img src="/infrastructure/7.jpeg" alt="Classroom" />
            <p className="legend">Well-equipped classrooms</p>
          </div>
         
        </Carousel>
      </div>
    </div>
  );
};

export default InfrastructureSection;
