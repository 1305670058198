import React from 'react'
import HeroSection from '../../components/ui/hero-section/HeroSection'
import CoursesSection from '../../components/ui/courses-offered/CoursesSection'
import InfrastructureSection from '../../components/ui/infrastructure/InfrastructureSection'
// import StatsSection from '../../components/ui/stats/StatsSection'
import SuccessStories from '../../components/ui/success-stories/SuccessStories'
import MessageFromDirector from '../../components/ui/director-message/MessageFromDirector'
import Testimonials from '../../components/ui/testimonials/Testimonials'
import ContactForm from '../../components/ui/contactform/ContactForm'
import PromoVideo from '../../components/ui/promovideo/PromoVideo'
import EventsSection from '../../components/ui/events/EventsSection'

const Home = () => {
  return (
    <>
    <HeroSection/>
    <CoursesSection/>
    <InfrastructureSection/>
    {/* <StatsSection/> */}
    <SuccessStories/>
    <MessageFromDirector/>
    <PromoVideo/>
    <Testimonials/>
    <EventsSection/>
    <ContactForm/>
    </>
  )
}

export default Home