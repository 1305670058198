import React from 'react';
import './Footer.css';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img src="/logo.png" alt="Jasingfa IAS Logo" />
      </div>
      <div className="footer-content">
        <div className="footer-links">
          <h3>Quick Links</h3>
          <ul>
  <li><Link to="/about">About Us</Link></li>
  <li><Link to="/courses">Courses</Link></li>
  <li><Link to="/success-stories">Success Stories</Link></li>
  <li><Link to="/contact">Contact Us</Link></li>
</ul>
        </div>
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>Email: jasingfafranchise@gmail.com</p>
          <p>Phone: +91 6026975397</p>
          <p>Address: Dibrugarh, Assam</p>
        </div>
        <div className="footer-social">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/share/8Pwa2Y1JaSAUx2XL/" target='_blank' rel="noreferrer" aria-label="Facebook"><FaFacebookF /></a>
            
            <a href="https://www.instagram.com/jasingfa_career_academy?igsh=anMxZHlzMzcxbTRt" rel="noreferrer" target='_blank' aria-label="Instagram"><FaInstagram /></a>
          
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Jasingfa IAS Academy. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
