import React from 'react';
import { FaChalkboardTeacher, FaBook, FaRegClock, FaRegStar, FaUsers } from 'react-icons/fa'; // Importing necessary icons
import { motion } from 'framer-motion'; // Importing animation library
import './WhyChooseUs.css'; // Importing CSS for styling

const WhyChooseUs = () => {
  const keyFeatures = [
    {
      icon: <FaRegClock />,
      title: 'Structured Study Plan',
      description: 'A detailed timeline to keep you on track.',
    },
    {
      icon: <FaRegStar />,
      title: 'Conceptual Clarity',
      description: 'Focus on analytical thinking and decision-making.',
    },
    {
      icon: <FaBook />,
      title: 'Current Affairs Analysis',
      description: 'Regular discussions on current events and trends.',
    },
    {
      icon: <FaChalkboardTeacher />,
      title: 'Case Studies',
      description: 'Scenario-based learning for real-world applications.',
    },
    {
      icon: <FaUsers />,
      title: 'Holistic Development',
      description: 'Fostering growth for Civil Services aspirants.',
    },
  ];

  const facultyProfile = [
    {
      icon: <FaChalkboardTeacher />,
      description: 'Experienced faculty from Tarun IAS, Delhi.',
    },
    {
      icon: <FaUsers />,
      description: 'Civil Services experts with proven track records.',
    },
    {
      icon: <FaRegStar />,
      description: 'Renowned academicians and thought leaders.',
    },
  ];

  const infrastructure = [
    {
      icon: <FaBook />,
      description: 'Well-equipped classrooms for interactive learning.',
    },
    {
      icon: <FaBook />,
      description: 'Extensive library resources available for students.',
    },
    {
      icon: <FaBook />,
      description: 'Access to online resources and digital libraries.',
    },
  ];

  return (
    <div className="why-choose-us-container">
      <h2>Why Choose Us?</h2>
      <div className="features">
        <h3>Key Features</h3>
        <div className="feature-cards">
          {keyFeatures.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <div className="icon">{feature.icon}</div>
              <h4>{feature.title}</h4>
              <p>{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="faculty">
        <h3>Faculty Profile</h3>
        <div className="faculty-cards">
          {facultyProfile.map((faculty, index) => (
            <motion.div
              key={index}
              className="faculty-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <div className="icon">{faculty.icon}</div>
              <p>{faculty.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="infrastructure">
        <h3>Infrastructure</h3>
        <div className="infrastructure-cards">
          {infrastructure.map((infra, index) => (
            <motion.div
              key={index}
              className="infrastructure-card"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <div className="icon">{infra.icon}</div>
              <p>{infra.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
