import React from 'react';
import { FaUserGraduate, FaChalkboardTeacher, FaBookOpen, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import './About.css'; // Ensure you have your CSS file linked

const About = () => {
  return (
   <div className="about">
     <div className="about-container" id="about">
      <h2>Unlock Your Potential for Civil Services Excellence</h2>
       
      <p>
        Jasingfa Srestha Siksha IAS ACADEMY, a leading institution in Dibrugarh, Assam, 
        has partnered with Tarun IAS, a renowned Civil Services guidance institute in Delhi, 
        to offer comprehensive preparation for UPSC and APSC exams.
      </p>

      <h3><FaUserGraduate /> Key Benefits of Joining Us:</h3>
      <ul>
        <li>Expert faculty from Tarun IAS, Delhi</li>
        <li>Access to Tarun IAS study materials and resources</li>
        <li>Regular interactive sessions with Delhi-based faculty</li>
        <li>Mock tests, group discussions, and interview practice</li>
        <li>Personalized mentoring and guidance</li>
      </ul>

      <h3><FaBookOpen /> Courses Offered:</h3>
      <ul>
        <li>Integrated UPSC (Prelims, Mains, and Interview)</li>
        <li>APSC (Assam Public Service Commission)</li>
        <li>Optional subjects (e.g., Assamese Literature, History, Geography)</li>
        <li>SSC, Banking, Railway & Other State Level Recruitment Exams</li>
      </ul>

      <h3><FaChalkboardTeacher /> Key Features:</h3>
      <ul>
        <li>Structured study plan and timeline</li>
        <li>Focus on conceptual clarity, analytical thinking, and decision-making</li>
        <li>Current affairs analysis and discussion</li>
        <li>Case studies and scenario-based learning</li>
        <li>Holistic development for Civil Services aspirants</li>
      </ul>    

      <h3>Infrastructure:</h3>
      <ul>
        <li>Well-equipped classrooms</li>
        <li>Library with extensive resources</li>
        <li>Online resources and digital library access</li>
      </ul>

      <h3>Our Team:</h3>
      <p>Our dedicated team of experts who are committed to guiding you through your Civil Services preparation.</p>
      <ul className="team-list">
        <li>
          
          <div>Academic Coordinator - Bhabana Gogoi</div>
        </li>
        <li>
       
          <div>Center Head- Arnab Handique</div>
        </li>
        <li>
         
          <div>Director -Nilim Handique </div>
        </li>
        <li>
         
          <div>Front Desk Executive -Rumashree Tamuli</div>
        </li>
        <li>
          
          <div>Office Coordinator - Ankush Dey</div>
        </li>


        {/* <li>
          <img src="https://i.pravatar.cc/100?img=6" alt="Team Member 3" />
          <div>Office Coordinator - Ankush Dey</div>
        </li> */}
      </ul>

     
 




      <h3>Contact Information:</h3>
      <p><FaPhoneAlt /> For inquiries, please reach out to us:</p>
      <p>Email: <FaEnvelope /> jasingfafranchise@gmail.com | Phone: +91-6026975397</p>

      <h3>Join us today and take the first step towards your dream career in Civil Services!</h3>
    </div>
   </div>
  );
};

export default About;
