import React, { useRef, useEffect } from 'react';
import './PromoVideo.css';

const PromoVideo = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    let observer;

    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the video is in view
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Check if the video is paused before playing
          if (videoElement.paused) {
            videoElement.play().catch((error) => {
              console.error("Error trying to play the video: ", error);
            });
          }
        } else {
          // Check if the video is playing before pausing
          if (!videoElement.paused) {
            videoElement.pause();
          }
        }
      });
    };

    observer = new IntersectionObserver(handleIntersection, options);
    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (observer && videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  return (
   <div className="promo">
     <div className="promo-video-container">
      <video
        ref={videoRef}
        className="promo-video"
        src="/videos/promovideo.mp4"
        muted // Mute the video for autoplay
        loop // Loop the video
        playsInline // For mobile compatibility
        controls // Show video controls
      />
    </div>
   </div>
  );
};

export default PromoVideo;
