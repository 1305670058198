import React from 'react';
import './MessageFromDirector.css';

const MessageFromDirector = () => {
  return (
    <div className="message-container">
      <div className="message-content">
        <h2>From the Director's Desk</h2>
        <p>Dear Aspirants,</p>
        <p>
          I am honored to welcome you to Jasingfa Srestha Siksha IAS Academy, a premier institution dedicated to nurturing future leaders for Civil Services. Our collaborative effort with Tarun IAS, Delhi, enhances our expertise in guiding you toward success.
        </p>
        <p>With Regards,</p>
        <h4>Nilim Handique</h4>
        <p>Director</p>
        <p>Jasingfa Srestha Siksha IAS ACADEMY</p>
      </div>
      <div className="director-photo">
        <img src="/director.jpeg" alt="Nilim Handique" />
      </div>
    </div>
  );
};

export default MessageFromDirector;
