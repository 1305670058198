import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';

const HeroSection = () => {
  const [currentImage, setCurrentImage] = useState('/hero/hero1.png'); // Default image
  const images = ['/hero/hero1.png', '/hero/hero2.png']; // Array of images
  let index = 0;

  useEffect(() => {
    const imageSwitcher = setInterval(() => {
      index = (index + 1) % images.length; // Cycle through images
      setCurrentImage(images[index]);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(imageSwitcher); // Cleanup interval on unmount
  }, []);

  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1>Jasingfa Srestha Siksha IAS ACADEMY</h1>
        <p>A leading institution in Dibrugarh, Assam, has partnered with Tarun IAS, a renowned Civil Services guidance institute in Delhi, to offer comprehensive preparation for UPSC and APSC exams.</p>
        <div className="hero-buttons">
          <Link to="/contact" className="btn btn-contact">Get in Touch</Link>
          <Link to="/contact" className="btn btn-brochure">Download Brochure</Link>
        </div>
      </div>
      <div className="hero-image">
        <img src={currentImage} alt="Hero" />
      </div>
    </div>
  );
};

export default HeroSection;
