import React from 'react';
import { FaBook, FaGlobe, FaLandmark, FaUniversity } from 'react-icons/fa'; // Importing icons from react-icons
import './CoursesSection.css';

const CoursesSection = () => {
  return (
    <div className="courses-container" id='courses'>
      <h2>Courses Offered</h2>
      <div className="courses-list">
        <div className="course-item gradient-one">
          <FaBook className="course-icon" />
          <h3>Integrated UPSC</h3>
          <p>Prelims, Mains, and Interview</p>
        </div>
        <div className="course-item gradient-two">
          <FaLandmark className="course-icon" />
          <h3>APSC</h3>
          <p>Assam Public Service Commission</p>
        </div>
        <div className="course-item gradient-three">
          <FaGlobe className="course-icon" />
          <h3>Optional Subjects</h3>
          <p>Assamese Literature, History, Geography, etc.</p>
        </div>
        <div className="course-item gradient-four">
          <FaUniversity className="course-icon" />
          <h3>SSC, Banking, Railway & Other State Exams</h3>
          <p>Comprehensive guidance for various competitive exams</p>
        </div>
      </div>
    </div>
  );
};

export default CoursesSection;
