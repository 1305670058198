import React, { useState } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa'; // Importing react-icons
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false); // State for loader
  const [success, setSuccess] = useState(false); // State for success message
  const [error, setError] = useState(''); // State for error handling

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      const response = await fetch('https://webartstudio.online/api-service/jasingfaias/form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      setLoading(false); // Hide loader

      if (result.status === 'success') {
        setSuccess(true); // Show success message
        setFormData({ name: '', email: '', message: '' }); // Reset form
      } else {
        setError(result.message); // Handle error
      }
    } catch (err) {
      setLoading(false); // Hide loader
      setError('Failed to submit the form. Please try again.');
    }
  };

  return (
    <div className="contact">
      <div className="contact-form-container">
        <h2>Contact Us</h2>
        <p>Email: <a href="mailto:jasingfafranchise@gmail.com">jasingfafranchise@gmail.com</a></p>
        <p>Phone: +91 6026975397</p>
        <p>Address: Dibrugarh, Assam</p>

        <div className="footer-social">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/share/8Pwa2Y1JaSAUx2XL/" aria-label="Facebook" className="facebook-icon" target='_blank' rel="noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/jasingfa_career_academy?igsh=anMxZHlzMzcxbTRt" aria-label="Instagram" className="instagram-icon" target='_blank' rel="noreferrer">
              <FaInstagram />
            </a>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Sending...' : 'Send Message'}
          </button>
        </form>

        {/* Loader */}
        {loading && <div className="loader">Loading...</div>}

        {/* Success Message */}
        {success && (
          <div className="success-popup">
            <p>Form successfully submitted!</p>
            <button onClick={() => setSuccess(false)}>Close</button>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="error-message">
            <p>{error}</p>
            <button onClick={() => setError('')}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
