import React from 'react';
import { Carousel } from 'react-responsive-carousel'; // Importing the carousel component
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importing carousel styles
import './Testimonials.css';

const Testimonials = () => {
  return (
    <div className="testimonials-container" id='testimonials'>
      <h2>What our students say about us.</h2>
      <Carousel 
        showThumbs={false} 
        autoPlay 
        infiniteLoop 
        interval={2000} 
        showStatus={false}
      >
        <div>
          <img src="/testimonials/1.jpeg" alt="Event 1" />
        </div>
        <div>
          <img src="/testimonials/2.jpeg" alt="Event 2" />
        </div>
        <div>
          <img src="/testimonials/3.jpeg" alt="Event 3" />
        </div>
        <div>
          <img src="/testimonials/4.jpeg" alt="Event 4" />
        </div>
        <div>
          <img src="/testimonials/5.jpeg" alt="Event 5" />
        </div>
        <div>
          <img src="/testimonials/6.jpeg" alt="Event 6" />
        </div>
        {/* Add more images as needed */}
      </Carousel>
    </div>
  );
};

export default Testimonials;
