import React from 'react';
import './SuccessStories.css';

const SuccessStories = () => {
  return (
    <div className="success-stories-container" id='success-stories'>
    <img src="/hero/hero1.png" alt="" />
    </div>
  );
};

export default SuccessStories;
